/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';

import Root from './containers/root';
import configureStore from './stores/configureStore';
import { AxiosInstancesContext } from './hoc/utils/axiosContext';
import { axiosInstances } from './hoc/utils/axiosUtils';

const { history, store } = configureStore();

export default () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div data-testid='app-test'>
        <Suspense fallback={<div></div>}>
          <AxiosInstancesContext.Provider value={axiosInstances}>
            <Root />
          </AxiosInstancesContext.Provider>
          <ReduxToastr
            timeOut={0}
            newestOnTop={false}
            preventDuplicates
            removeOnHover={false}
            removeOnHoverTimeOut={10000}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            //progressBar
            closeOnToastrClick={false}
          />
        </Suspense>
      </div>
    </ConnectedRouter>
  </Provider>
);
