import React from 'react';
import { Row, Col } from 'reactstrap';

import LogoImg from './../../assets/img/cropped-logo-eocoe-alta-risoluzione-q-192x192.png';
import PCSSImg from './../../assets/img/footer-logo-pcss.png';
import EUImg from './../../assets/img/eu-funding.png';

export default () => (
  <footer className="pb-5">
    <div id="footer-text" className="border-top border-dark w-75 mx-auto mb-5 text-center">
      The implementation of the portal was funded from the European Commission Horizon 2020 project No. 824158 (“EoCoE-II”)
      and cofounded from the programme of the Polish Minister of Science and Higher Education entitled "PMW"
      in the years 2019 - 2021; agreement no. 5057/H2020/19/2020/2
    </div>
    <Row noGutters>
      <Col xs="4" className="d-flex justify-content-end align-items-center">
        <img src={LogoImg} className="eocoe-logo" alt="EoCoE logo"/>
      </Col>
      <Col xs="4" className="d-flex justify-content-center align-items-center">
        <img src={EUImg} className="ec-logo" alt="European Commission"/>
      </Col>
      <Col xs="4" className="d-flex justify-content-begin align-items-center pt-5">
        Developed by
        <img src={PCSSImg} className="pcss-logo" alt="PCSS"/>
      </Col>
    </Row>
  </footer>
);
