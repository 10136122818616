/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { withSendRequest } from '../../../main/hoc/withSendRequest';
import * as notify from '../../utils/notify';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return `${props.t('queue_state_switch')}`;
})
@withTranslation()
@withSendRequest
export default class TaskCanceller extends Component {

  cancel = () => {
    const { t } = this.props;
    this.props.sendRequest('delete', '/jobs/' + this.props.taskId + '/')
      .then(response => {
        //Canceling operation successfully started
        notify.success(t('task_cancel_success_notification_title'), this.props.taskId + t('task_cancel_success_notification_body'));
        this.props.refresh();
      })
      .catch(function (error) {
        //Canceling failed
        notify.error(t('task_cancel_error_notification_title'), error.message);
        throw error;
      });
  };

  render() {
    const { loading, t } = this.props;
    const allowStates = [ 'PENDING', 'EXECUTING' ];
    const disabled = loading || !allowStates.includes(this.props.currentState) ;
    return (
      <Button
        size="sm"
        onClick={this.cancel}
        disabled={disabled}
        className="task-canceler"
        color="danger"
      >
        {t('cancel_task')}
      </Button>
    );
  }
}

TaskCanceller.propTypes = {
  taskId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  currentState: PropTypes.string,
  t: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
};
