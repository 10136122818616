/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { withSendRequest } from '../../../main/hoc/withSendRequest';
import { loadTemplates } from '../../actions/templates-actions';
import Area from '../../components/area';
import TemplateList from './templateList';
import TemplateDetails from './templateDetails';

@withTranslation()
@connect(
  state => ({
    templates: state.templates,
  }),
  dispatch => {
    return bindActionCreators({
      loadTemplates: loadTemplates,
    }, dispatch);
  }
)
@withSendRequest
export default class Templates extends Component {
  state = {
    activeTemplate: undefined,
  };

  chooseTemplate = url => () => {
    this.setState({ activeTemplate: url });
  };

  componentDidMount() {
    this.props.loadTemplates({ sendRequest: this.props.sendRequest });
  }

  render() {
    return (
      <div className="mt-4">
        <Row>
          <Col sm="3">
            <Area title={this.props.t('template_view.templates')}>
              <TemplateList chooseTemplate={this.chooseTemplate} activeTemplate={this.state.activeTemplate} />
            </Area>
          </Col>
          <Col sm="9">
            <Area title={this.props.t('template_view.template_details')}>
              <TemplateDetails templateUrl={this.state.activeTemplate} />
            </Area>
          </Col>
        </Row>
      </div>
    );
  }
};

Templates.propTypes = {
  t: PropTypes.func, //HOC
  templates: PropTypes.object, //HOC
  loadTemplates: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
};
