/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Legend, Pie, Cell, Tooltip } from 'recharts';

import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { colorPalette } from '../utils/charts-utils';

@ErrorBoundary((props) => {
  return `${props.t('queuesTaskCountChart')}`;
})
@withTranslation()
export default class QueuesTaskCountChart extends Component {
  renderCustomTooltip = ({ active, payload }) => {
    if (active)
      return (
        <div className="chart-tooltip">
          <span>
            <strong>{payload[0].name}</strong>
            {': ' + payload[0].value}
          </span>
        </div>
      );
    else return null;
  };

  renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - 5 - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (value === 0) return null;
    else return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {value}
      </text>
    );
  };

  renderQueuesTaskCountChart(data) {
    const JSData = data.toJS();
    return (
      <ResponsiveContainer width={!this.props.testEnviroment ? "100%" : 600} height={300}>
        <PieChart>
          <Legend verticalAlign="bottom" height={36}/>
          <Tooltip content={this.renderCustomTooltip} />
          <Pie
            data={JSData}
            dataKey="value"
            nameKey="name"
            innerRadius={40}
            outerRadius={80}
            label={this.renderCustomLabel}
            labelLine={false}
            isAnimationActive={false}
          >
            {JSData.map((entry, index) => <Cell key={index} fill={colorPalette[index % colorPalette.length]}/>)}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className='chart-wrapper'>
        <div className="text-center">
          {t('queuesTaskCountChart')}
        </div>
        {this.props.queuesTaskCount.size === 0 ? (
          <div className='no-data-overlay'>{t('no_data_for_chart')}</div>
        ) : (
          this.renderQueuesTaskCountChart(this.props.queuesTaskCount)
        )}
      </div>
    );
  }
}

QueuesTaskCountChart.propTypes = {
  queuesTaskCount: PropTypes.object, //TODO: use immutable prop type
  testEnviroment: PropTypes.any,
  t: PropTypes.func, //HOC
};
