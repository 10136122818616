/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import * as axios from 'axios';

import { __env } from '../../../envloader';
import { AccessTokenProvider } from './accessTokenProvider';
import * as notify from '../../utils/notify';
import i18n from '../../utils/i18nConfigProvider';
import { getLogger } from '../../utils/logger';

const accessTokenProvider = new AccessTokenProvider();

// Creating axios instances to use once in root and pass as context
export const axiosInstances = {
  authInstance: axios.create(),
  axiosInstance: axios.create({
    baseURL: __env.QCG_API_URL
  })
};

export const sendRequest = (instances) => (method, address, data, retryCount = { "500": 0, "400": -1 }) => {
  return accessTokenProvider.obtainToken(instances.authInstance)
    .then(token => {
      return instances.axiosInstance({
        url: address,
        method: method,
        headers: { 'Authorization': 'Bearer ' + token },
        data: data
      })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              // accessToken expired - refresh accessToken
              return accessTokenProvider.obtainToken(instances.authInstance, true)
                .then(token => {
                  // new accessToken obtained - send last request again
                  return new Promise(function (resolve) {
                    setTimeout(resolve.bind(null, {}), 1000 * retryCount["400"]);
                  }).then(() => {
                    retryCount["400"] += 1;
                    return sendRequest(instances)(method, address, data, retryCount);
                  });
                });
            }
            //other error than accessToken expired and there is more than 0 retries
            else if (error.response.status >= 500 && error.response.status <= 599
              && retryCount["500"] < __env.RETRY_REQUEST_INTERVALS.length && (method === 'get' || method === 'head')) {
              //retry same request and decrement retries count
              return new Promise(function (resolve) {
                setTimeout(resolve.bind(null, {}), 1000 * __env.RETRY_REQUEST_INTERVALS[retryCount["500"]]);
              }).then(() => {
                retryCount["500"] += 1;
                return sendRequest(instances)(method, address, data, retryCount);
              });
            }
            else if (error.response.status >= 500 && error.response.status <= 599
              && retryCount < __env.RETRY_REQUEST_INTERVALS.length)
              notify.error(i18n.t('common:server_error'), error.message);
            else {
              //other error than 401 or 5xx - timeout or network error
              notify.error(i18n.t('common:error'), error.message);
            }
          }
          else {
            notify.error(i18n.t('common:error'), error.message);
            getLogger({ loggerName: 'axios-utils' }).error(error);
          }
          throw error;
        });
    });
};

export const sendAuthRequest = (instances) => (method, address) => {
  return accessTokenProvider.obtainToken(instances.authInstance)
    .then(token => {
      return instances.authInstance({
        url: address,
        method: method,
        headers: { 'Authorization': 'Bearer ' + token }
      })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            // accessToken expired - refresh accessToken
            return accessTokenProvider.obtainToken(instances.authInstance, true)
              .then(token => {
                // new accessToken obtained - send last request again
                return sendAuthRequest(instances)(method, address);
              });
          }
          else throw error;
        });
    });
};
