/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withSendRequest } from '../../../main/hoc/withSendRequest';
import { Link } from 'react-router-dom';
import { getCurrentLanguageShortCode } from '../../utils/templates-utils';

@withTranslation()
@connect(
  state => ({
    templates: state.templates,
  })
)
@withSendRequest
export default class TemplateDetails extends Component {
  render() {
    const { t } = this.props;
    const templateData = this.props.templates.get(this.props.templateUrl);
    const languages = templateData && templateData.get('description').keySeq().toJS().join(', ');
    const shortLngCode = getCurrentLanguageShortCode();
    if (templateData)
      return (
        <div>
          <h6>{t('template_view.metadata')}:</h6>
          <Table bordered size="sm">
            <tbody>
              <tr>
                <td className="text-right">{t('template_view.name')}:</td>
                <td className="text-left">{templateData.get('name')}</td>

                <td className="text-right">{t('template_view.version')}:</td>
                <td className="text-left">{templateData.get('version')}</td>
              </tr>
              <tr>
                <td className="text-right">{t('template_view.languages')}:</td>
                <td className="text-left">{languages}</td>

                <td className="text-right">{t('template_view.url')}:</td>
                <td className="text-left">{this.props.templateUrl}</td>
              </tr>
              <tr>
                <td className="text-right">{t('template_view.template_description')}:</td>
                <td colSpan={3} className="text-left">{templateData.getIn([ 'description', shortLngCode ], templateData.first())}</td>
              </tr>
            </tbody>
          </Table>
          <div className="d-flex flex-column">
            <Button
              tag={Link}
              to={'/ui/submit?templateUrl=' + this.props.templateUrl}
              size="sm"
              color="primary"
              className="align-self-end mt-3"
            >
              {t('template_view.use_to_submit')}
            </Button>
          </div>
        </div>
      );
    else
      return null;
  }
};

TemplateDetails.propTypes = {
  t: PropTypes.func, //HOC
  templates: PropTypes.object, //HOC
  sendRequest: PropTypes.func, //HOC
  templateUrl: PropTypes.string,
};
