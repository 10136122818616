/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { Nav, NavItem, NavLink, Collapse, Row, Col, Navbar, NavbarBrand, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Login from '../../main/containers/login';
import { __env } from '../../envloader';
//import LogoMinImg from './../../assets/img/logo-min.svg';
//import LogoMinImg from './../../assets/img/cropped-logo-eocoe-alta-risoluzione-q-32x32.png';
import LogoImg from './../../assets/img/cropped-logo-eocoe-alta-risoluzione-q-192x192.png';

@withTranslation()
@connect(
  state => ({
    loginStore: state.login.get('loginData')
  })
)
export default class TopBar extends Component {

  renderNavItem(address, name) {
    return (
      <NavItem className="nav-menu-item">
        <Route path={address} children={({ match }) => (
          <NavLink tag={Link} to={address} active={!!match}>{name}</NavLink>
        )}/>
      </NavItem>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <Container fluid className="py-2" id="topbar">
        <Row>
          <Col>
            <Navbar color="faded" expand>

              <NavbarBrand tag={Link} to="/">
                <img src={LogoImg} className="logo" alt="logo"/>
                <span id="main-title">{t('name')}</span>
              </NavbarBrand>

              <Collapse navbar className="justify-content-end">
                {
                  //show navitems only when logged in
                  this.props.loginStore &&
                  <Nav navbar pills>
                    {__env.ENABLE_TEMPLATES_AND_SUBMIT && this.renderNavItem('/ui/templates/', t('templates'))}
                    {__env.ENABLE_TEMPLATES_AND_SUBMIT && this.renderNavItem('/ui/submit/', t('submit'))}
                    {this.renderNavItem('/ui/tasks/', t('tasks'))}
                  </Nav>
                }

                <Nav navbar pills >
                  <Login location={this.props.location}/>
                </Nav>
              </Collapse>

            </Navbar>
          </Col>
        </Row>
      </Container>
    );
  }
}

TopBar.propTypes = {
  t: PropTypes.func, //HOC
  loginStore: PropTypes.object, //HOC
  location: PropTypes.object,
};
