/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import { Map, List } from 'immutable';
import {
  LOAD_QUEUES_TASK_COUNT_DATA,
  LOAD_WEEK_INTERVAL_DATA,
  LOAD_DAY_INTERVAL_DATA,
  LOAD_HOUR_INTERVAL_DATA,
  LOAD_MINUTE_INTERVAL_DATA
} from '../actions/charts-actions';

import { queuesTaskCountParseData, intervalTimeParseData } from '../utils/charts-utils';

const chartsDefaultState = Map({
  queuesTaskCount: List(),

  weekIntervalData: List(),
  dayIntervalData: List(),
  hourIntervalData: List(),
  minuteIntervalData: List(),
});

export const chartsReducer  = (state = chartsDefaultState, action) => {
  switch (action.type) {
  case LOAD_QUEUES_TASK_COUNT_DATA:
    return state.set('queuesTaskCount', queuesTaskCountParseData(action.payload));

  case LOAD_WEEK_INTERVAL_DATA:
    return state.set('weekIntervalData', intervalTimeParseData(action.payload));

  case LOAD_DAY_INTERVAL_DATA:
    return state.set('dayIntervalData', intervalTimeParseData(action.payload, 'day'));

  case LOAD_HOUR_INTERVAL_DATA:
    return state.set('hourIntervalData', intervalTimeParseData(action.payload, 'hour'));

  case LOAD_MINUTE_INTERVAL_DATA:
    return state.set('minuteIntervalData', intervalTimeParseData(action.payload, 'minute'));

  default:
    return state;
  }
};