/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../main/hoc/errorboundary';
import QueuesTaskCountChart from './queuesTaskCountChart';
import IntervalQueuesCountChart from './intervalQueuesCountChart';

@ErrorBoundary((props) => {
  return `${props.t('cluster_charts')}`;
})
@connect(
  state => ({
    charts: state.charts,
  })
)
export default class Charts extends Component {
  render() {
    const { charts } = this.props;
    return (
      <div id="charts">
        <QueuesTaskCountChart queuesTaskCount={charts.get('queuesTaskCount')}/>
        <IntervalQueuesCountChart
          dayData={charts.get('dayIntervalData')}
          hourData={charts.get('hourIntervalData')}
          minuteData={charts.get('minuteIntervalData')}
        />
      </div>
    );
  }
}

Charts.propTypes = {
  charts: PropTypes.object, //HOC
};
