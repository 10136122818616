/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return `${props.t('show_file_button')}`;
})
export default class ShowFileButton extends Component {
  state = {
    modal: false
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    const { fileName, fileContent, size } = this.props;
    return (
      <span>
        <Button className="show_file" size={size} onClick={this.toggle} >
          { this.props.children }
        </Button>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg" >
          <ModalHeader toggle={this.toggle}><b>{fileName}</b></ModalHeader>
          <ModalBody>
            <div className="pre-scrollable">
              <pre>{JSON.stringify(fileContent, null, 2)}</pre>
            </div>
          </ModalBody>
        </Modal>
      </span>
    );
  };
}

ShowFileButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileContent: PropTypes.object.isRequired, //TODO: use immutable prop type
  size: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
