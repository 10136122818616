/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReactTable from 'react-table';
import { Button } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { titledHeader } from '../utils/table-utils';
import QueueStateSwitch from '../containers/queueStateSwitch';
import { ErrorBoundary } from '../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return `${props.t('queues')}`;
})
@withTranslation()
@connect(
  state => ({
    resources: state.resources,
  })
)
export default class Queues extends Component {
  state = {
    loading: false,
  };

  setLoadingFlag = (bool, callback) => {
    this.setState({ loading: bool }, callback);
  };

  render() {
    const { t, resources, fetchData } = this.props;
    const data = resources.getIn([ 'queues', 'list' ]) && resources.getIn([ 'queues', 'list' ]).map((queue, name) => ({
      name: name,
      queue_state: queue.get('state'),
      pending: queue.getIn([ 'job_states', 'PENDING', 'count' ], 0),
      running: queue.getIn([ 'job_states', 'RUNNING', 'count' ], 0),
      max_eta: queue.getIn([ 'job_states', 'PENDING', 'max_eta' ], 0)
    })).toArray();

    const columns = [
      {
        Header: titledHeader(t('queue_column')),
        headerClassName: "row-header",
        accessor: "name",
        className: "name",
        Footer: (<strong>{t('total')}</strong>),
        Cell: row => titledHeader(row.value)
      },
      {
        Header: titledHeader(t('pending_tasks')),
        headerClassName: "row-header",
        accessor: "pending",
        className: "pending",
        Cell: row => (
          <div className="pending-cell">
            {row.value} ({(((row.value * 100) / _.sum(_.map(data, d => d.pending))) || 0).toFixed(1)}%)
            <div>
              <Button
                data-testid={row.original.name + '-pending-button'}
                tag={Link}
                to={'/ui/tasks/?queue=' + row.original.name + '&state=PENDING'}
                size="sm"
                color="primary"
                outline
                className="show-queue-tasks py-0"
              >
                {t('show_tasks')}
              </Button>
            </div>
          </div>
        ),
        Footer: (
          <span>
            {_.sum(_.map(data, d => d.pending))}
            <div>
              <Button
                tag={Link}
                to={'/ui/tasks/?state=PENDING'}
                size="sm"
                color="primary"
                className="py-0"
              >
                {t('show_tasks')}
              </Button>
            </div>
          </span>
        )
      },
      {
        Header: titledHeader(t('running_tasks')),
        headerClassName: "row-header",
        accessor: "running",
        className: "running",
        Cell: row => (
          <div className="running-cell">
            {row.value} ({(((row.value * 100) / _.sum(_.map(data, d => d.running))) || 0).toFixed(1)}%)
            <div>
              <Button
                data-testid={row.original.name + '-executing-button'}
                tag={Link}
                to={'/ui/tasks/?queue=' + row.original.name + '&state=EXECUTING'}
                size="sm"
                color="primary"
                outline
                className="show-queue-tasks py-0"
              >
                {t('show_tasks')}
              </Button>
            </div>
          </div>
        ),
        Footer: (
          <span> {_.sum(_.map(data, d => d.running))}
            <div>
              <Button
                tag={Link}
                to={'/ui/tasks/?state=EXECUTING'}
                size="sm"
                color="primary"
                className="py-0"
              >
                {t('show_tasks')}
              </Button>
            </div>
          </span>
        )
      },
      {
        Header: titledHeader(t('max_eta')),
        headerClassName: "row-header",
        accessor: "max_eta",
        className: "max_eta",
        Cell: row => (
          row.value ? moment(row.value).format("DD.MM.Y HH:mm") : "-"
        ),
        Footer: "-",
      },
      {
        Header: titledHeader(t('queue_state')),
        headerClassName: "row-header",
        accessor: "queue_state",
        className: 'queue-state',
        Cell: row => <QueueStateSwitch
          queueName={row.original.name}
          queueState={row.original.queue_state}
          fetchData={fetchData}
          setLoadingFlag={this.setLoadingFlag}
        />
      }
    ];
    return (
      <div>
        <ReactTable
          columns={columns}
          data={data}
          sortable={false}
          minRows={5}
          showPageSizeOptions={false}
          showPagination={false}
          onFetchData={this.fetchData}
          loading={this.state.loading}
        />
        <div className="under-table">
          <span>
            {t('all_task_count')}: <span className="bold">{this.state.tasksCount}</span>
          </span>
          <Button
            tag={Link}
            to={'/ui/tasks/'}
            size="sm"
            color="primary"
            className="float-right mt-1"
          >
            {t('task_registry')}
          </Button>
        </div>
      </div>
    );
  };
}

Queues.propTypes = {
  fetchData: PropTypes.func.isRequired,
  resources: PropTypes.object, //HOC
  t: PropTypes.func, //HOC
};
