import React, { Component }  from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { __env } from '../../../envloader';
import FileInput from '../fileSelectorProviders/fileInput';
import IframeModal from '../fileSelectorProviders/iframeModal';

@withTranslation()
export class CustomFileWidget extends Component {
  constructor(props) {
    super(props);
    this.state  = {
      fileSelectorProviderModal: false,
      fileSelectorProviders: __env.SUBMIT_FORM_FILE_SELECTOR_PROVIDERS.map(provider => this.parseProvider(provider)),
      currentProvider: null
    };
  }

  parseProvider = (provider) => {
    return {
      id: provider.id,
      name: this.props.t('fileSelectorWidget.' + provider.id),
      component: provider.type === 'custom' ? 
        <FileInput submitValue={this.submitValue} onClose={this.providerOnClose} /> : provider.type === 'url' ? 
          <IframeModal 
            submitValue={this.submitValue} 
            onClose={this.providerOnClose} 
            url={provider.url} 
            title={this.props.t('fileSelectorWidget.' + provider.id)} 
            provider={true} /> : null
    };
  };

  providerOnClose = () => {
    this.setState({ currentProvider: null });
  };

  submitValue = (value) => {
    this.props.onChange(value);
    this.toggleModal();
    this.providerOnClose();
  };

  toggleModal = () => {
    if (this.state.fileSelectorProviders.length === 1) {
      this.setState({ currentProvider: this.state.fileSelectorProviders[0].id });
    }
    else {
      this.setState(prevState => ({ fileSelectorProviderModal: !prevState.fileSelectorProviderModal }));
    }
  };

  renderOption(provider, index) {
    return (
      <Button
        outline
        block
        key={index}
        onClick={() => this.setState({ currentProvider: provider.id })}
      >
        {provider.name}
      </Button>
    );
  }

  renderProvider() {
    const provider = this.state.fileSelectorProviders.find(element => element.id === this.state.currentProvider);
    return provider ? provider.component : null;
  }

  renderFileSelectorProviderModal() {
    return !this.state.currentProvider && (
      <Modal size='sm' isOpen={this.state.fileSelectorProviderModal}>
        <ModalHeader toggle={this.toggleModal}>{this.props.t('fileSelectorWidget.selectFile')}</ModalHeader>
        <ModalBody>
          <div className='mb-1'>
            {this.props.t('fileSelectorWidget.fileSelectorProvider')}
          </div>
          {this.state.fileSelectorProviders.map((provider, index) => this.renderOption(provider, index))}
        </ModalBody>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <Button onClick={this.toggleModal}>{this.props.t('fileSelectorWidget.selectFile')}</Button>
        <span className='ml-2'>{this.props.value}</span>
        {this.renderFileSelectorProviderModal()}
        {this.renderProvider()}
      </div>
    );
  }
}

CustomFileWidget.propTypes = {
  t: PropTypes.func, //HOC
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default CustomFileWidget;