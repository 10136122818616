/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { TiClipboard } from 'react-icons/ti';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Map, List, fromJS } from 'immutable';
import { FiRefreshCw } from 'react-icons/fi';

import { formatDate } from '../../utils/tasksgrid-utils';
import { withSendRequest } from '../../../main/hoc/withSendRequest';
import ShowFileButton from '../../components/showFileButton';
import QueueMover from './queueMover';
import TaskState from '../../components/taskState';
import TaskCanceler from './taskCanceler';
import RefreshFeature from '../../components/refreshFeature';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import ShowOutputFiles from './showOutputFiles';

/* ******* CUSTOMIZATION *********** */

const getCustomFieldsFunction = () => {};
const renderCustomContentFunction = () => {};
const getAdditionalDataLoadersFunction = () => {};

/* ******* CUSTOMIZATION-END ******* */


@ErrorBoundary((props) => {
  return `${props.t('task_details_modal')}`;
}, true)
@withTranslation()
@withSendRequest
export default class TaskDetailsModal extends Component {
  state = {
    data: Map(),
    history: List(),
    loading: false,
    overload: undefined,
    additionalData: Map(),
    storages: {},
  };

  componentDidMount() {
    this.loadTaskData();
    this.requestAPI('/storages/')
      .then((response) => {
        this.setState({ storages: response });
      });
  }

  loadTaskData = () => {
    this.setState({ loading: true });
    this.requestAPI('/jobs/' + this.props.taskId + '/')
      .then(data => {
        if (data.operation) {
          // Some operation in progress -> retry
          setTimeout(this.loadTaskData, 1000);
        }
        else {
          // There is no operation in progress -> set state
          this.setState(
            { data: fromJS(data), loading: false },
            this.loadAdditionalData
          );
          this.loadTaskHistory();
        }
      });
  };

  loadAdditionalData = () => {
    const loaders = getAdditionalDataLoadersFunction(this.state.data, this.props.sendAuthRequest, this.props.t) || [];
    loaders.forEach(item => {
      item.loader()
        .then(result => {
          this.setState({ additionalData: this.state.additionalData.set(item.name, result) });
        });
    });
  };


  // TODO: This function is temporal solution. Finally that should be realized by backend.
  decomposeTaskErrors(results) {
    if (!results) return null;
    for (let i=0; i<results.length-1; i++) {
      //cut all old errors
      results[i]['error'] = results[i+1]['errors'] ? results[i]['errors'].slice(0,-(results[i+1]['errors'].length)) : results[i]['errors'];
      //cut '. ' if any
      results[i]['error'] = (results[i]['error'] && results[i]['error'].endsWith('. ')) ? results[i]['error'].slice(0,-2): results[i]['error'];
    }
    //set the last one to its own errors (can be longer in case of paging)
    results[results.length-1]['error']=results[results.length-1]['errors'];

    return results;
  };

  loadTaskHistory = () => {
    this.requestAPI('/jobs/' + this.props.taskId + '/events/')
      .then(data => {
        if (data.count > data.results.length)
          this.setState({ overload: data.count - data.results.length });
        this.setState({ history: fromJS(this.decomposeTaskErrors(data.results)) });
        this.setState({ errors: data.results ? data.results[0]['errors']:null });
      });
  };

  requestAPI = (query) => {
    return this.props.sendRequest("get", query)
      .then((response) => {
        return (response.data);
      });
  };

  refresh = () => {
    this.loadTaskData();
  };

  close = () => {
    this.props.onClose();
  };

  renderTaskField = (value, description, link) => {
    return (
      <li key={description}>
        <span className="task-field-name">{description}</span>:&nbsp;
        <span className="bold">
          {link
            ? <a href={value}><span className="task-field-value">{value}</span></a>
            : <span className="task-field-value">{value}</span>
          }
        </span>
      </li>
    );
  };

  renderHistory = () => {
    return this.state.history.map(record => {
      return (
        <tr key={record.get('created')}>
          <td>{formatDate(record.get('created'), false, record.get('event_id') + '_created')}</td>
          <td><TaskState state={record.get('state')}/></td>
          <td>{record.get('queue')}</td>
          <td>{record.get('operation')} <span className="badge badge-danger wrap">{record.get('error')}</span></td>
        </tr>
      );
    }).toJS();
  };

  render() {
    const { t } = this.props;
    const task = this.state.data;
    const customFieldsArray = getCustomFieldsFunction(task, this.state.additionalData, t) || [];
    return (
      <div>
        <Modal isOpen={true} toggle={this.close} size="lg" className="task-details-modal" data-testid='task-details-modal'>
          <ModalHeader className="task-details-modal-header" toggle={this.close}>{t("details_of_task_no")} {this.props.taskId}</ModalHeader>
          <ModalBody>
            <div className='refresh-feature-box mb-2 right-panel'>
              <RefreshFeature
                minValue={10}
                maxValue={100}
                default={30}
                fetchData={this.refresh}
              />
            </div>
            { renderCustomContentFunction(task, t) }
            <ul className="task-fields-list">
              { this.renderTaskField(task.get('id'), t('id_column')) }
              { this.renderTaskField(task.get('user'), t('user_id')) }

              { customFieldsArray.map(item =>
                this.renderTaskField(item.field, item.description, item.link)
              )
              }

              { this.renderTaskField(task.get('queue'), t('current_queue')) }
              { this.renderTaskField(task.get('state'), t('current_state')) }
              { this.renderTaskField(formatDate(task.get('submit_time'), false, 'submit-time-' + task.get('id')), t('submit_time_column')) }
              { this.renderTaskField(formatDate(task.get('eta'), false, 'eta-' + task.get('id')), t('eta_column')) }
              { this.renderTaskField(task.get('local_id'), t('local_id')) }
              { this.renderTaskField(task.get('local_user'), t('local_user')) }
              { this.renderTaskField(task.get('work_dir'), t('work_dir')) }
              { this.renderTaskField(task.get('nodes'), t('nodes_column')) }
            </ul>
            <ShowFileButton fileName={t('details_json')} fileContent={task} size="sm">
              <TiClipboard size="20px" id="text-file-icon" />{t('show_details_json')}
            </ShowFileButton>
            <h2>{t("tasks_history")}</h2>
            <div className="task-history-body">
              <Button
                onClick={this.loadTaskHistory}
                className="task-history-refresh"
                size="sm"
                color="link"
              >
                <FiRefreshCw/>
              </Button>
              <table className="task-history">
                <thead>
                  <tr>
                    <th>{t("time_column")}</th>
                    <th>{t("state_column")}</th>
                    <th>{t("queue_column")}</th>
                    <th>
                      {t("operation_column")}
                      {this.state.errors && <span> / <span className="badge badge-danger wrap">{t("operation_or_error_column")}</span></span>}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderHistory()}
                </tbody>
              </table>
              {this.state.overload && <div className='text-center mt-2'>{t('tasks_history_overload', { count: this.state.overload })}</div>}
            </div>
            {this.state.storages && (task.get('state') === 'FINISHED' || task.get('state') === 'FAILED' || task.get('state') === 'CANCELED') &&
            <ShowOutputFiles
              files={this.state.data.get('files') ? this.state.data.get('files').toJS() : []}
              storages={this.state.storages}
              taskId={this.props.taskId} />}
            <h2>{t("actions_column")}</h2>
            <QueueMover
              taskId={this.props.taskId}
              loading={this.state.loading}
              refresh={this.refresh}
              currentState={task.get('state')}
            />
            <TaskCanceler
              taskId={this.props.taskId}
              loading={this.state.loading}
              refresh={this.refresh}
              currentState={task.get('state')}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

TaskDetailsModal.propTypes = {
  taskId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
  sendAuthRequest: PropTypes.func, //HOC
};
