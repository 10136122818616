/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { useState } from 'react';
import { Button, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import IframeModal from '../fileSelectorProviders/iframeModal';

export default function ShowOutputFiles({ files, storages, taskId }) {
  const { t } = useTranslation();

  const outputFiles = files.filter(file => file.type === 'OUTPUT' && file.storage);

  const [ state, setState ] = useState({
    modal: false,
    file: null
  });

  function closeModal() {
    setState({ ...state, modal: false });
  }

  const showFile = (file) => {
    setState({
      modal: true,
      file: file
    });
  };

  function renderFiles() {
    return (
      <div>
        {
          outputFiles.map((file, index) => {
            return (
              <Row key={taskId + '_' + index}>
                <Button onClick={() => showFile(file)} style={{ width: '300px' }}>{file.name}</Button>
              </Row>
            );
          })
        }
      </div>
    );
  }
  if (storages && outputFiles.length > 0)
    return (
      <div>
        <h2>{t("output_files")}</h2>
        {renderFiles()}
        {state.modal &&
          <IframeModal 
            onClose={closeModal} 
            url={storages[state.file.storage.name].ui_url + storages[state.file.storage.name].path
              .replace('$QCG_JOB_ID', taskId) + (state.file.storage.targetname || state.file.local_name)} 
            title={t('output_files')} 
          />
        }
      </div>
    );
  return null;
}

ShowOutputFiles.propTypes = {
  files: PropTypes.array, 
  storages: PropTypes.object, 
  taskId: PropTypes.string,
};