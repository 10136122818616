/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

const namespace = '@QCG_TEMPLATES/';

export const LOAD_TEMPLATE = namespace + 'LOAD_TEMPLATE';

export const loadTemplates = ({ sendRequest }) => dispatch => {
  //TODO: errors handing and empty lists notifying
  sendRequest("get", "/templates/")
    .then(response => {
      const templates = response.data && response.data.templates;
      if (templates && Array.isArray(templates) && templates.length > 0) {
        //template list is not empty
        templates.forEach((template, index) => {
          if (template.name) {
            dispatch({
              type: LOAD_TEMPLATE,
              payload: {
                id: template.source,
                template,
              }
            });
          }
        });
      }
    });
};
