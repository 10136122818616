/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ErrorBoundary } from '../../../main/hoc/errorboundary';

@ErrorBoundary(props => props.t('submit_view.submit_success_modal'), true)
@withTranslation()
export default class SubmitSuccessModal extends Component {
  render() {
    return (
      <Modal isOpen={true} onClose={this.props.onClose} size="md" centered={true}>
        <ModalHeader>
          {this.props.t('submit_view.job_submit_success_modal_header')}
        </ModalHeader>
        <ModalBody>
          {this.props.t('submit_view.job_submit_success_modal_body', { id: this.props.id })}
        </ModalBody>
        <ModalFooter>
          <Button
            tag={Link}
            to={'/ui/tasks/?details=' + this.props.id}
            color="info"
          >
            {this.props.t('submit_view.see_task_details')}
          </Button>
          <Button onClick={this.props.onClose} color="primary">
            {this.props.t('submit_view.submit_another')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

SubmitSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  t: PropTypes.func, //HOC
};
