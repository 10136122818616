import React from 'react';
import { Row, Col, Jumbotron, Button } from 'reactstrap';
import { Link } from 'react-scroll';

import Footer from './footer';

export default () => {
  return (
    <div className="bg-white">
      <Jumbotron className="landing-jumbotron">
        <div className="titles">
          <p className="mb-0">Energy oriented Center of Excellence:</p>
          <h1>TOWARD EXASCALE FOR <span className="text-secondary">ENERGY</span></h1>
          <p className="lid">At the crossroads of the energy and digital revolutions, EoCoE develops and applies cutting-edge computational methods
            in its mission to accelerate the transition to the production, storage and management of clean, decarbonized energy. </p>
          <Button tag={Link} className="px-5 mt-2 text-white" to="about" smooth="easeInOutCubic" duration={1000}>About</Button>
        </div>
      </Jumbotron>
      <Row id="about" noGutters>
        <Col sm="12" md="6" className="mt-5 d-flex justify-content-center">
          <div className="concrete-list-title">
          EoCoE provides numerical tools in the following areas:
          </div>
        </Col>
        <Col sm="12" md="6" className="mt-5 d-flex justify-content-center">
          <Row className="concrete-list" noGutters>
            <Col xs="12" sm="6">
              <div className="marker">01.</div>
              CFD air-flow modelling for wind farm assessment
            </Col>
            <Col xs="12" sm="6">
              <div className="marker">02.</div>
              Short-term forecasting to predict power output of wind/solar plants
            </Col>
            <Col xs="12" sm="6">
              <div className="marker">03.</div>
              Geothermal and heat reservoir modelling
            </Col>
            <Col xs="12" sm="6">
              <div className="marker">04.</div>
              State-of-the-art linear algebra methods
            </Col>
            <Col xs="12" sm="6">
              <div className="marker">05.</div>
              Visualization
            </Col>
            <Col xs="12" sm="6">
              <div className="marker">06.</div>
              Large weather ensembles for predicting extreme events in the weather/wind/PV power forecast
            </Col>
            <Col xs="12" sm="6">
              <div className="marker">07.</div>
              Advanced programming methods for Exascale
            </Col>
            <Col xs="12" sm="6">
              <div className="marker">08.</div>
              Predictive hydropower capacity modelling
            </Col>
            <Col xs="12" sm="6">
              <div className="marker">09.</div>
              Multi-scale simulation methods (molecular dynamics, kinetic Monte Carlo, quantum Monte Carlo, ...)
              for materials modelling (solar cells, batteries, supercapacitors....)
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer/>
    </div>
  );
};
