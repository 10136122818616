import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
// import all locales suported by ui
import 'moment/locale/pl';

import Callback from './callback';
import LogoutCallback from './logoutCallback';
import { loginCallbackPath, logoutCallbackPath } from '../utils/authConfigProvider';
import LoginToAccess from '../components/loginToAccess';
import NotFound from '../components/notFound';
import Loading from '../components/loading';
import { __env } from '../../envloader';

import TopBar from '../../qcg-ui/containers/topbar';
import Dashboard from '../../qcg-ui/containers/dashboard';
import Templates from '../../qcg-ui/containers/templates/templates';
import Submit from '../../qcg-ui/containers/submit/submit';
import TasksGrid from '../../qcg-ui/containers/tasks/tasksGrid';
import '../../qcg-ui/styles/index.scss';

import Landing from '../../eocoe/components/landing';


@withTranslation()
@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  })
)
export default class Root extends Component {
  renderContent = () => {

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading className="text-center mt-5" />;
    }

    if (!this.props.loginStore) {
      //User not logged in
      return (
        <Switch>
          <Route exact strict path='/' component={Landing} />
          { __env.ENABLE_TEMPLATES_AND_SUBMIT && <Route exact path='/ui/templates/' component={LoginToAccess}/> }
          { __env.ENABLE_TEMPLATES_AND_SUBMIT && <Route exact path='/ui/submit/' component={LoginToAccess}/> }
          <Route exact path='/ui/tasks/' component={LoginToAccess} />
          <Route path={ loginCallbackPath } exact component={Callback} />
          <Route path={ logoutCallbackPath } exact component={LogoutCallback} />
          <Route component={NotFound} />
        </Switch>
      );
    }
    else {
      //User logged in
      return (
        <div id="main-panel">
          <Switch>
            <Route exact strict path="/">
              {__env.ENABLE_DASHBOARD ? <Dashboard /> : <Redirect to="/ui/tasks/"/>}
            </Route>
            { __env.ENABLE_TEMPLATES_AND_SUBMIT && <Route exact path='/ui/templates/' component={Templates}/> }
            { __env.ENABLE_TEMPLATES_AND_SUBMIT && <Route exact path='/ui/submit/' component={Submit}/> }
            <Route exact path='/ui/tasks/' component={TasksGrid}/>
            <Route component={NotFound} />
          </Switch>
        </div>
      );
    }
  };

  render() {

    // Global internationalization of moment library
    moment.locale(this.props.t("language_code_for_moment"));

    return (
      <div>
        <TopBar />
        <Container fluid className="mb-2 px-0">
          <div className="main-content">
            {this.renderContent()}
          </div>
        </Container>
      </div>
    );
  }
}

Root.propTypes = {
  t: PropTypes.func, //HOC
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
};
