/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import { Map, fromJS } from 'immutable';
import {
  LOAD_RESOURCES_DATA,
  LOAD_TOTAL_TASKS_COUNT,
  LOAD_PENDING_TASKS_COUNT,
  LOAD_EXECUTING_TASKS_COUNT,
  LOAD_MAX_ETA
} from '../actions/resources_actions';

export const resourcesReducer  = (state = Map(), action) => {
  switch (action.type) {

  case LOAD_RESOURCES_DATA:
    return action.payload ? fromJS(action.payload) : state;

  case LOAD_TOTAL_TASKS_COUNT:
    return state.set('total_tasks', action.payload);

  case LOAD_PENDING_TASKS_COUNT:
    return state.setIn([ 'queues', 'list', action.payload.name, 'job_states', 'PENDING', 'count' ], action.payload.count);

  case LOAD_EXECUTING_TASKS_COUNT:
    return state.setIn([ 'queues', 'list', action.payload.name, 'job_states', 'RUNNING', 'count' ], action.payload.count);

  case LOAD_MAX_ETA:
    return state.setIn([ 'queues', 'list', action.payload.name, 'job_states', 'PENDING', 'max_eta' ], action.payload.eta);

  default:
    return state;
  }
};
