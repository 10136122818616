/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FiRefreshCw } from 'react-icons/fi';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return `${props.t('refresh_feature_component')}`;
})
@withTranslation()
export default class RefreshFeature extends Component {
  state = {
    checked: false,
    refreshIntervalTime: this.props.default
  };
  interval;

  validateInput = (numericInput) => {
    if (numericInput > this.props.maxValue)
      this.handleNumericInputChange({
        target: {
          value: this.props.maxValue
        }
      });
    else if (numericInput < this.props.minValue)
      this.handleNumericInputChange({
        target: {
          value: this.props.minValue
        }
      });
  };

  handleSwitchChange = () => {
    this.validateInput(this.state.refreshIntervalTime);
    this.setState({ checked: !this.state.checked }, this.refreshHandler);
  };

  handleNumericInputChange = (event) => {
    if (event.target.value > this.props.maxValue)
      this.setState({ refreshIntervalTime: this.props.maxValue });
    else if (event.target.value < 0)
      this.setState({ refreshIntervalTime: 0 });
    else
      this.setState({ refreshIntervalTime: event.target.value });
  };

  refreshHandler = () => {
    if (this.state.checked)
      this.interval = setInterval(() => {
        this.props.fetchData();
      }, this.state.refreshIntervalTime * 1000);
    else
      clearInterval(this.interval);
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { t } = this.props;
    return (
      <div className='refresh-feature'>
        <Switch
          data-testid='refresh-feature-switch'
          onChange={this.handleSwitchChange}
          checked={this.state.checked}
          height={22}
          width={42}
          offColor="#191919"
          onColor="#e80808"
        />
        &nbsp;
        <InputGroup size="sm">
          <InputGroupAddon addonType="prepend">
            {t('automatic_refresh')}
          </InputGroupAddon>
          <Input
            type="number"
            step="1"
            style={{ width: 60 }}
            disabled={this.state.checked}
            value={this.state.refreshIntervalTime}
            onChange={this.handleNumericInputChange}
            onBlur={() => this.validateInput(this.state.refreshIntervalTime)}
            onMouseOut={() => this.validateInput(this.state.refreshIntervalTime)}
          />
          <InputGroupAddon addonType="append">
            {t('sec')}
          </InputGroupAddon>
          <InputGroupAddon addonType="append">
            <Button
              data-testid='refresh-feature-button'
              id='refreshButton'
              color="primary"
              outline
              className='refresh-button'
              size='sm'
              onClick={this.props.fetchData}
            >
              <FiRefreshCw/>
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    );
  }
}

RefreshFeature.propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  default: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
  t: PropTypes.func, //HOC
};
