/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { FaTimesCircle } from 'react-icons/fa';

import { withSendRequest } from '../../../main/hoc/withSendRequest';
import TaskState from '../../components/taskState';
import * as notify from '../../utils/notify';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return `${props.t('canceling_action_modal')}`;
}, true)
@withTranslation()
@withSendRequest
export default class CancelingActionModal extends Component {
  state = {
    tasksStates: Map(),
    tasksCanceling: Map(),
  };

  componentDidMount() {
    //Load tasks states
    for (let i=0; i<this.props.idsArray.length; i++) {
      const id = this.props.idsArray[i];
      this.props.sendRequest('get', '/jobs/' + id + '/')
        .then(response => {
          this.setState(prevState => ({ tasksStates: prevState.tasksStates.set(id, response.data.state) }));
        });
    }
  }

  close = () => {
    this.props.onClose();
  };

  cancel = () => {
    const { t } = this.props;
    for (let i=0; i<this.props.idsArray.length; i++) {
      const id = this.props.idsArray[i];
      this.setState(prevState => ({ tasksCanceling: prevState.tasksCanceling.set(id, 'CANCELING') }));
      this.props.sendRequest('delete', '/jobs/' + id + '/')
        .then(response => {
          this.setState(prevState => ({ tasksCanceling: prevState.tasksCanceling.set(id, 'DONE') }));
        })
        .catch(error => {
          //TODO: decide if these notifications are needed - redundand info on modal dialog as icons
          notify.error(t('task_cancel_error_notification_title'), error.message);
          this.setState(prevState => ({ tasksCanceling: prevState.tasksCanceling.set(id, 'FAILED') }));
        });
    }
  };

  render() {
    const { t } = this.props;
    const allowedStates = [ 'PENDING', 'EXECUTING' ];
    let allInAllowedState = true;
    for (let i=0; i < this.props.idsArray.length; i++) {
      if (!allowedStates.includes(this.state.tasksStates.get(this.props.idsArray[i]))) {
        allInAllowedState = false;
      }
    }
    return (
      <div id="canceling-action-modal">
        <Modal isOpen={true} toggle={this.close}>
          <ModalHeader toggle={this.close}>{t('batch_tasks_canceling')}</ModalHeader>
          <ModalBody>
            <p>{t('cancel_following_tasks')}:</p>
            <ul>
              {this.props.idsArray.map(id => {
                const state = this.state.tasksStates.get(id);
                const cancelingStatus = this.state.tasksCanceling.get(id, undefined);
                return (
                  <li key={id}>
                    {id}
                    <TaskState state={state} className="mx-1"/>
                    {cancelingStatus === 'CANCELING' && <Spinner color="secondary" size="sm" className="ml-1" />}
                    {cancelingStatus === 'DONE' && <span data-testid='canceling-done' className="ml-1 text-secondary done-icon"><FaCheckCircle/></span>}
                    {cancelingStatus === 'FAILED' && <span data-testid='canceling-failed' className="ml-1 text-primary failed-icon"><FaTimesCircle/></span>}
                  </li>
                );
              })}
            </ul>
            <Button
              id="cancel-tasks"
              size="sm"
              onClick={this.cancel}
              disabled={!allInAllowedState}
              color="primary"
            >
              {t('cancel_tasks')}
            </Button>
            {
              !allInAllowedState &&
              <p className="text-danger"><small>{t('all_tasks_must_be_PENDING_or_EXECUTING')}</small></p>
            }
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

CancelingActionModal.propTypes = {
  idsArray: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  sendRequest: PropTypes.func, //HOC
  t: PropTypes.func, //HOC
};
