/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { FaFileInvoice } from 'react-icons/fa';

@connect(
  state => ({
    templates: state.templates,
  })
)
export default class TemplateList extends Component {
  render() {
    return (
      <Table hover borderless size="sm">
        <tbody>
          {this.props.templates.entrySeq().map(([ key, item ]) => (
            <tr
              key={key}
              className={key === this.props.activeTemplate ? "table-primary": ""}
              onClick={this.props.chooseTemplate(key)}
            >
              <td className="cursor-pointer">
                <FaFileInvoice />
                <span className="ml-2">{item.get('name')} (ver: {item.get('version')})</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

TemplateList.propTypes = {
  templates: PropTypes.object, //HOC
  chooseTemplate: PropTypes.func,
  activeTemplate: PropTypes.string,
};
