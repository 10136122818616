import React, { useState } from 'react';
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const FileInput = (props) => {
  const [ value, setValue ] = useState(undefined);

  return (
    <Modal isOpen={true}>
      <ModalHeader toggle={props.onClose}>{props.t('fileSelectorWidget.userInput')}</ModalHeader>
      <ModalBody>
        <Input placeholder={props.t('fileSelectorWidget.userInputPlaceholder')} onChange={event => setValue(event.target.value)}/>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => props.submitValue(value)}>
          {props.t('fileSelectorWidget.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

FileInput.propTypes = {
  t: PropTypes.func, //HOC
  onClose: PropTypes.func,
  submitValue: PropTypes.func,
};

export default withTranslation()(FileInput);