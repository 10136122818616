/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Legend, Tooltip } from 'recharts';

import { getDataForModeChart, series, colorPalette } from '../utils/charts-utils';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import XAxisTick from './xAxisTick';

@ErrorBoundary((props) => {
  return `${props.t('intervalQueuesCountChart')}`;
})
@withTranslation()
export default class IntervalQueuesCountChart extends Component {
  state = {
    mode: 'day',
  };

  renderQueuesTimeIntervalState(data) {
    const JSData = data.toJS();
    return (
      <ResponsiveContainer width={!this.props.testEnviroment ? "100%" : 600} height={300}>
        <LineChart data={JSData} margin={{ right: 50 }}>
          <XAxis dataKey="name" tick={<XAxisTick />} />
          <YAxis />
          <Tooltip />
          <Legend iconType='rect' wrapperStyle={{ marginLeft: 50 }}/>
          {series.map((series, index) => <Line key={index} dot={false} dataKey={series} stroke={colorPalette[index % colorPalette.length]} />)}
        </LineChart>
      </ResponsiveContainer>
    );
  }

  render() {
    const { t } = this.props;
    const chartData = getDataForModeChart(this.state.mode, this.props);
    return (
      <div className='chart-wrapper mt-5'>
        <div className="text-center">
          {t('intervalQueuesCountChart')}
        </div>
        {chartData.size === 0 ? (
          <div className='no-data-overlay'>{t('no_data_for_chart')}</div>
        ) : (
          this.renderQueuesTimeIntervalState(chartData)
        )}
        <div className='text-center mt-3'>
          <Button
            active={'day' === this.state.mode}
            outline
            color='primary'
            size='sm'
            onClick={() => this.setState({ mode: 'day' })}>{t('day')}
          </Button>
          &nbsp;
          <Button
            active={'hour' === this.state.mode}
            outline
            color='primary'
            id='changeIntervalToHour'
            size='sm'
            onClick={() => this.setState({ mode: 'hour' })}>{t('hour')}
          </Button>
          &nbsp;
          <Button
            active={'minute' === this.state.mode}
            outline
            color='primary'
            size='sm'
            onClick={() => this.setState({ mode: 'minute' })}>{t('minute')}
          </Button>
        </div>
      </div>
    );
  }
}

IntervalQueuesCountChart.propTypes = {
  dayData: PropTypes.object,
  hourData: PropTypes.object,
  minuteData: PropTypes.object,
  testEnviroment: PropTypes.any,
  t: PropTypes.func, //HOC
};