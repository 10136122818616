/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { ErrorBoundary } from '../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return `${props.t('task_state')}`;
})
export default class TaskState extends Component {

  mapStateToColor = state => {
    switch (state) {
    case 'FINISHED': return { color: 'success' };
    case 'FAILED': return { color: 'danger' };
    case 'EXECUTING': return { color: 'warning' };
    case 'PENDING': return { color: 'info' };
    case 'COMPLETING': return { color: 'info', style: { backgroundColor: '#ff7300' } };
    case 'CANCELED': return { color: 'dark' };
    default: return { color: 'light' };
    }
  };

  render() {
    const attributes = this.mapStateToColor(this.props.state);
    return this.props.state ? (
      <Badge {...attributes} className={this.props.className}>
        {this.props.state}
      </Badge>
    ) : null;
  }
};

TaskState.propTypes = {
  state: PropTypes.string,
  className: PropTypes.string,
};
