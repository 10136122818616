/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React from 'react';
import { axiosInstances } from './axiosUtils';

// Context object to use in root(Provider) and below(Consumer)
export const AxiosInstancesContext = React.createContext(axiosInstances);
